import React, { createContext, useCallback, useState, useEffect, useRef, useContext } from "react";
import { useHistory } from 'react-router';
import * as uuid from 'uuid';

import { BRAND, Campaign, CustomConfig, TripInfo, TripTypes } from "@type";
import { getBuilder, isCustomCruiseLine } from "@helpers/Utils";
import { APPLICATION_STATUSES, PartnerTypes } from "@helpers/Constants";
import { LeaveConfirmationModal } from "@components";
import { useTracking } from "@hooks/useTracking";
import { AuthenticationContext } from "./Authentication";

export type Partner = {
  countries: Array<{
    code: string;
    UPC: string;
    enabled: boolean;
  }>;
  industry: PartnerTypes;
  tenantId: string;
  hasWhiteLabel: boolean;
}

export type GlobalData = {
  partners: Array<Partner>,
  tripInfo: TripInfo;
  sendBy: string;
  currentPartner?: Partner;
  currentPartnerConfig?: CustomConfig;
  currentStep: number;

  // 0% APR
  hasSubvention: boolean;

  // Offer related information
  offerId?: string;
  offerStatus: APPLICATION_STATUSES;

  // Metrics related
  firstInteraction: boolean;

  // Id for match events before an order id is set
  preOfferId: string;

  // Global variable for onboard spend, to know if we sent the application or not
  applicationOnSameDevice: boolean;

  //VOIP validation error
  voip: boolean;
}

// Trip details control variables
export type TripDetails = {
  tripsSelected: Array<TripTypes>;
  tripOneWay: boolean;
  departureDate: string;
  arrivalDate: string;
  travelersCount: number;
  additionalProductsRequiredError: boolean;
}

// Cruise details control variables
export type CruiseDetails = {
  selectedCruiseLine: string;
  customCruiseLine: string;
  shipOptions: Array<{value: string, label: string}>;
  shipCode: string;
}

// Borrower Information control variables
export type BorrowerDetails = {
  borrowerOnReservation: boolean;
  borrowerName: string;
  borrowerFamilyName: string;
  borrowerEmail: string;
  borrowerPhone: string;
}

// Country selector control Variables
export type CountrySelectorDetails = {
  countrySelectorDirty: boolean;
}

// Global shutdown control variables
export type GlobalSettingsShutdown = {
  universalAlert: boolean;
  settingsFetched: boolean;
  universalShutdown: boolean;
  dictionary: {
    en: {
      universalShutdownMsg: string;
      universalAlertMsg: string;
    },
    fr: {
      universalShutdownMsg: string;
      universalAlertMsg: string;
    }
  }
}

export type GlobalContextType = {
  globalData: GlobalData,
  setGlobalData: Function,
  getCustomBookingWindow: Function,
  getCustomFilterAmounts: Function,

  // Trip details
  tripDetails: TripDetails;
  setTripsSelected: Function;
  setTripOneWay: Function;
  setDepartureDate: Function;
  setArrivalDate: Function;
  setTravelersCount: Function;
  setAdditionalProductsRequiredError: Function;

  // Cruise details
  cruiseDetails: CruiseDetails;
  setSelectedCruiseLine: Function;
  setShipOptions: Function;
  setCustomCruiseLine: Function;
  setShipCode: Function;

  // Borrower details
  borrowerDetails: BorrowerDetails;
  setBorrowerDetails: Function;

  // Conutry selector details
  countrySelectorDetails: CountrySelectorDetails;
  setCountrySelectorDetails: Function;

  // Metrics control variables
  registerFirstInteraction: Function;

  // Global shutdown
  globalShutdown: GlobalSettingsShutdown;
  setGlobalShutdown: Function;

  // Block refresh
  handleBlockRefresh: EventListenerOrEventListenerObject;

  // Campaigns
  setCampaigns: Function;

  // Refs for modals
  refs: {
    leaveConfirmationModal: any;
    generalErrorModal: any;
  }
};

const GlobalContext = createContext({} as GlobalContextType);

const Global = (props: any) => {
  const { agentEmail, agentId } = useContext(AuthenticationContext);

  const [globalData, setGlobalData] = useState<GlobalData>(() => {
    return {
      tripInfo: {
        agent: {
          email: agentEmail || '',
          agent_id: agentId || ''
        },
        localization: {
          currency: '',
          language: 'en',
          country: '',
        },
        order_amount: 0,
        merchant_data: { path: '/' },
        up_code: '',
        brand: BRAND.FLEXPAY
      },
      partners: [],
      sendBy: 'email',
      hasSubvention: false,
      currentStep: 0,
      offerStatus: APPLICATION_STATUSES.none,
      firstInteraction: false,
      preOfferId: uuid.v4(),
      handleBlockRefresh: (e: any) => {},
      applicationOnSameDevice: false,
      voip: false
    }
  });

  const [tripDetails, setTripDetails] = useState<TripDetails>({
    tripsSelected: [],
    additionalProductsRequiredError: false,
    tripOneWay: false,
    departureDate: '',
    arrivalDate: '',
    travelersCount: 1,
  });

  const [cruiseDetails, setCruiseDetails] = useState<CruiseDetails>({
    selectedCruiseLine: '',
    shipOptions: [],
    customCruiseLine: '',
    shipCode: '',
  });

  const [borrowerDetails, setBorrowerDetails] = useState<BorrowerDetails>({
    borrowerOnReservation: true,
    borrowerEmail: '',
    borrowerFamilyName: '',
    borrowerName: '',
    borrowerPhone: '',
  });

  const [countrySelectorDetails, setCountrySelectorDetails] = useState<CountrySelectorDetails>({
    countrySelectorDirty: false,
  });

  const [globalShutdown, setGlobalShutdown] = useState<GlobalSettingsShutdown>({
    settingsFetched: false,
    universalAlert: false,
    universalShutdown: false,
    dictionary: {
      en: {
        universalShutdownMsg: "Our system is temporarily unavailable. Access will be restored as soon as possible. Sorry for the inconvenience.",
        universalAlertMsg: ""
      },
      fr: {
        universalShutdownMsg: "Notre système est temporairement indisponible. L'accès sera rétabli dès que possible. Désolé pour le dérangement.",
        universalAlertMsg: ""
      }
    }
  })

  const history = useHistory();
  const leaveConfirmationModal : any = useRef();
  const generalErrorModal : any = useRef();

  const { trackClick } = useTracking();

  // Global getters
  const getCustomBookingWindow = useCallback(() => {
    const defaultBookingWindow = [11, 365];
    if (!globalData.currentPartnerConfig) return defaultBookingWindow;
    if (!globalData.currentPartnerConfig['booking-window']) return defaultBookingWindow;

    const hasMinBookingWindow = typeof globalData.currentPartnerConfig['booking-window'].min === 'number';
    const hasMaxBookingWindow = typeof globalData.currentPartnerConfig['booking-window'].max === 'number';

    return [
      hasMinBookingWindow ? globalData.currentPartnerConfig['booking-window'].min + 1 : defaultBookingWindow[0],
      hasMaxBookingWindow ? globalData.currentPartnerConfig['booking-window'].max + 1 : defaultBookingWindow[1]
    ]

  }, [globalData.currentPartnerConfig]);

  const getCustomFilterAmounts = useCallback(() => {
    const { currentPartnerConfig } = globalData;

    if (!currentPartnerConfig) return [150_00, 15_000_00];
    if (!currentPartnerConfig['filter']) return [150_00, 15_000_00];

    return [currentPartnerConfig['filter'].min, currentPartnerConfig['filter'].max]

  }, [globalData]);

  // TripDetails setters
  const setTripsSelected = useCallback((trips: Array<TripTypes>) => {
    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        tripsSelected: [...trips]
      }
    });
  }, [setTripDetails]);

  const setTripOneWay = useCallback((isOneWay: boolean) => {
    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        tripOneWay: isOneWay
      }
    });
  }, [setTripDetails]);

  const setDepartureDate = useCallback((departureDate: string) => {
    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        departureDate
      }
    });
  }, [setTripDetails]);

  const setArrivalDate = useCallback((arrivalDate: string) => {
    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        arrivalDate
      }
    });
  }, [setTripDetails]);

  const setTravelersCount = useCallback((travelersCount: number) => {
    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        travelersCount
      }
    });
  }, [setTripDetails]);

  // Set additional products required
  const setAdditionalProductsRequiredError = useCallback((additionalProductsRequiredError: boolean) => {
    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        additionalProductsRequiredError
      }
    });
  }, [setTripDetails]);

  // CruiseDetails setters
  const setShipOptions = useCallback((options: Array<{value: string, label: string}>) => {
    setCruiseDetails((cd: CruiseDetails) => {
      return {
        ...cd,
        shipOptions: options
      }
    })
  }, [setCruiseDetails]);

  const setSelectedCruiseLine = useCallback((cruiseLine: string) => {
    if (isCustomCruiseLine(cruiseLine)) return;

    setCruiseDetails((cd: CruiseDetails) => {
      return {
        ...cd,
        selectedCruiseLine: cruiseLine
      }
    })
  }, [setCruiseDetails]);

  const setCustomCruiseLine = useCallback((cruiseLine: string) => {
    setCruiseDetails((cd: CruiseDetails) => {
      return {
        ...cd,
        customCruiseLine: cruiseLine
      }
    })
  }, [setCruiseDetails]);

  const setShipCode = useCallback((shipCode: string) => {
    setCruiseDetails((cd: CruiseDetails) => {
      return {
        ...cd,
        shipCode
      }
    })
  }, [setCruiseDetails]);

  // Campaigns
  const setCampaigns = useCallback((campaigns: [Campaign]) => {
    setGlobalData((gd: GlobalData) => {
      const tripInfo = gd.tripInfo
      return {
        ...gd,
        tripInfo: {
          ...tripInfo,
          campaigns: campaigns
        }
      }
    })
  }, [setGlobalData])

  useEffect(() => {
    const departureDate = new Date();
    const arrivalDate = new Date();

    departureDate.setDate(departureDate.getDate() + 11);
    arrivalDate.setDate(arrivalDate.getDate() + 13);

    setTripDetails((td: TripDetails) => {
      return {
        ...td,
        departureDate: departureDate.toISOString().split('T')[0],
        arrivalDate: arrivalDate.toISOString().split('T')[0],
      }
    });
  }, []);

  useEffect(() => {
    if (!globalData.currentPartner) return;

    if (globalData.currentPartner.industry === PartnerTypes.NonTravel) {
      const order_lines = [getBuilder(TripTypes.other)()];
      setGlobalData((gd: GlobalData) => {
        return {
          ...gd,
          tripInfo: {
            ...gd.tripInfo,
            order_lines
          }
        }
      });
    }
  }, [globalData.currentPartner]);

  useEffect(() => {
    setGlobalData((gd: GlobalData) => {
      return {
        ...gd,
        tripInfo: {
          ...gd.tripInfo,
          agent: {
            ...gd.tripInfo.agent,
            email: agentEmail,
            agent_id: agentId
          }
        }
      }
    });
  }, [agentEmail, agentId, setGlobalData])

  const registerFirstInteraction = useCallback(() => {
    if (globalData.firstInteraction) return;
    trackClick('first-interaction', globalData.preOfferId);

    setGlobalData((gd: GlobalData) => {
      return {
        ...gd,
        firstInteraction: true
      }
    });
  }, [globalData.firstInteraction, trackClick, globalData.preOfferId]);

  const handleBlockRefresh = useCallback((event: any) => {
    event.preventDefault();
    event.returnValue = '';
}, []);

  const handleUnblockNavigation = useCallback(() => {
    if (leaveConfirmationModal.current.unblock) leaveConfirmationModal.current.unblock();

    if (leaveConfirmationModal.current.actions && Array.isArray(leaveConfirmationModal.current.actions)) {
      leaveConfirmationModal.current.actions.reduce((acc: string, action: Function) => action(), '');
    }

    if (leaveConfirmationModal.current.blockedLocation)
      history.push(leaveConfirmationModal.current.blockedLocation);
  }, [history]);

  return (
    <GlobalContext.Provider value={{
      globalData,
      setGlobalData,
      getCustomFilterAmounts,
      getCustomBookingWindow,
      // Trip Details
      tripDetails,
      setTripsSelected,
      setTripOneWay,
      setArrivalDate,
      setDepartureDate,
      setTravelersCount,
      setAdditionalProductsRequiredError,

      // Cruises
      cruiseDetails,
      setShipOptions,
      setSelectedCruiseLine,
      setCustomCruiseLine,
      setShipCode,

      // Borrower
      borrowerDetails,
      setBorrowerDetails,

      // Country selector details
      countrySelectorDetails,
      setCountrySelectorDetails,

      // Register first interaction for metrics
      registerFirstInteraction,

      // Global Shutdown setings
      globalShutdown,
      setGlobalShutdown,

      // Block Refresh
      handleBlockRefresh,

      // Campaigns
      setCampaigns,

      // Refs for modals
      refs: {
        leaveConfirmationModal,
        generalErrorModal
      }
    }}>
      {props.children}
      <LeaveConfirmationModal ref={leaveConfirmationModal} handleUnblockNavigation={handleUnblockNavigation}/>
    </GlobalContext.Provider>
  );
};

export { Global, GlobalContext };