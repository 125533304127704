import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components';
import './generalErrorModal.scss';

function GeneralErrorModal(props: any, ref: any) {
    const { t } = useTranslation(['general-error-modal', 'common']);
    const tips: Array<any> = t('tips', { returnObjects: true });
    return (
        <Modal id="general-error" ref={ref} className="modal-booking-failed" name="general-error"
            header={
                <h2 className="modal-title">
                    {t('title')}
                </h2>
            }
            body={
                <div className="modal-body-text">
                    <p className="modal-small">
                        {t('warning')}
                    </p>

                    {tips.map((tip, index) => (<div className="error-tip-item" key={index}>
                        <p data-index={index+1} className="modal-small">{tip}</p>
                    </div>))}

                    <div>
                        <span  className="modal-small">
                            {t('if-persists')}
                        </span>
                        <span className="modal-small">
                            {t('upgrade-number')}
                        </span>
                        <span className="modal-small">
                            {t('so-we-help')}
                        </span>
                    </div>
                </div>
            }
            buttons={[{
                label: t('common:back'),
                className: 'btn center-buton',
                onClick: () => { ref.current?.hideModal(); }
            }]}
        />
)
}

export default React.forwardRef(GeneralErrorModal);
