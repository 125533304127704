import React from 'react';
import { Modal } from '@components';
import { useTranslation } from 'react-i18next';

import './signOutModal.scss';

declare type SignOutModalProps = {
    onConfirmSignOut: Function
};

function SignOutModal({ onConfirmSignOut }: SignOutModalProps, ref: any) {
    const { t } = useTranslation(['sign-out-modal', 'common', 'navbar']);
    const tips: Array<any> = t('tips', { returnObjects: true });

    return (
        <Modal id="confirm-sign-out" ref={ref} className="confirm-sign-out" name="confirm-sign-out"
            header={
                <h2 className="modal-title">
                    {t('title')}
                </h2>
            }
            body={
                <div className="modal-body-text">
                    <p className='grey-text'>
                        {t('warning')}
                    </p>

                    {tips.map((tip, index) => (<div className="error-tip-item" key={index}>
                        <p data-index={index+1} className="modal-small">{tip}</p>
                    </div>))}
                </div>
            }
            buttons={[{
                label: t('common:cancel'),
                className: 'btn btn-outline-primary btn-cancel',
                onClick: () => { ref.current?.hideModal(); }
            },
            {
                label: t('navbar:sign-out'),
                className: 'btn btn-primary',
                onClick: () => { ref.current?.hideModal(); setTimeout(() => onConfirmSignOut(), 100)}
            }]}
        />)
}

export default React.forwardRef(SignOutModal);
