// Countries
import { ReactComponent as CALogo } from './Uplift/countries/CA.svg';
import { ReactComponent as USLogo } from './Uplift/countries/US.svg';
import { ReactComponent as OtherCountryLogo } from './Uplift/countries/Other.svg';

// Trips
import { ReactComponent as CarsLogo } from './Uplift/trips/car.svg';
import { ReactComponent as CuriseLogo } from './Uplift/trips/cruise.svg';
import { ReactComponent as FlightLogo } from './Uplift/trips/flight.svg';
import { ReactComponent as FractionalLogo } from './Uplift/trips/fractional.svg';
import { ReactComponent as HotelLogo } from './Uplift/trips/hotel.svg';
import { ReactComponent as InsuranceLogo } from './Uplift/trips/insurance.svg';
import { ReactComponent as MembershipLogo } from './Uplift/trips/membership.svg';
import { ReactComponent as PackageLogo } from './Uplift/trips/package.svg';
import { ReactComponent as OtherTripLogo } from './Uplift/trips/other.svg';

// Helpful tips
import { ReactComponent as Calendar } from './Uplift/tips/calendar.svg';
import { ReactComponent as Coin } from './Uplift/tips/coin.svg';
import { ReactComponent as CreditScore } from './Uplift/tips/credit_score.svg';
import { ReactComponent as Fee } from './Uplift/tips/fee.svg';
import { ReactComponent as InterestRates } from './Uplift/tips/interest_rates.svg';
import { ReactComponent as Timer } from './Uplift/tips/timer.svg';
import { ReactComponent as NoAPR } from './Uplift/tips/no-apr.svg';
import { ReactComponent as Play } from './Uplift/tips/play.svg';
import { ReactComponent as Calculator } from './Uplift/tips/calculator.svg';
import { ReactComponent as CalculatorPercent } from './Uplift/tips/calculator-percent.svg';
import { ReactComponent as WorldGreen } from './Uplift/tips/world-green.svg';
import { ReactComponent as Sailing } from './Uplift/tips/sailing.svg';
import { ReactComponent as Cart } from './Uplift/tips/cart.svg';
import { ReactComponent as Percent } from './Uplift/tips/percent.svg';
import { ReactComponent as CircleOne } from './Uplift/tips/numbers/one.svg';
import { ReactComponent as CircleTwo } from './Uplift/tips/numbers/two.svg';
import { ReactComponent as Application } from './Uplift/tips/application.svg';
import { ReactComponent as WorldGreenOutline } from './Uplift/tips/world-green-outline.svg';
import { ReactComponent as ShoppingBag } from './Uplift/tips/shopping-bag.svg';
import { ReactComponent as AmountRequested } from './Uplift/tips/amount-requested.svg';

// TrackSteps
import { ReactComponent as Send } from './Uplift/tracker/send.svg';
import { ReactComponent as InProgress } from './Uplift/tracker/in-progress.svg';
import { ReactComponent as ApplicationDesicion } from './Uplift/tracker/application-desicion.svg';
import { ReactComponent as ClientDesicion } from './Uplift/tracker/client-desicion.svg';
import { ReactComponent as Purchase } from './Uplift/tracker/purchase.svg';

// Application Progress
import {ReactComponent as AgentIcon} from './Uplift/applicationInProgress/agent.svg';
import {ReactComponent as LinkIcon} from './Uplift/applicationInProgress/link.svg';
import {ReactComponent as ApplicationForm} from './Uplift/applicationInProgress/applicationForm.svg';
import {ReactComponent as CheckmarkIcon} from './Uplift/checkmark.svg';
import {ReactComponent as CopyIcon} from './Uplift/copy.svg';
import { ReactComponent as LoanApprovedIcon } from './Uplift/applicationInProgress/loanApproved.svg';
import { ReactComponent as LoanDocumentationIcon } from './Uplift/applicationInProgress/loanDocumentation.svg';
import { ReactComponent as CardIcon } from './Uplift/applicationInProgress/card.svg';
import { ReactComponent as FinishIcon } from './Uplift/applicationInProgress/finish.svg';

// Misc
import {ReactComponent as WorldIcon} from './Uplift/world.svg';
import {ReactComponent as InfoIcon} from './Uplift/info-circle.svg';
import {ReactComponent as InfoIconFilled } from './Uplift/info-circle-filled.svg';
import {ReactComponent as WarningIcon } from './Uplift/warning-icon.svg';
import {ReactComponent as OpenInFullIcon } from './Uplift/open-in-full.svg';
import {ReactComponent as LinkStartApplicationIcon } from './Uplift/link.svg';

export const MAP_COUNTRY_CODES_AND_LOGOS: any = {
    US: USLogo,
    CA: CALogo, 
    Other: OtherCountryLogo,
};

export const MAP_TRIPS_AND_LOGOS: any = {
    car: CarsLogo,
    cruise: CuriseLogo,
    flight: FlightLogo,
    fractional: FractionalLogo,
    hotel: HotelLogo,
    insurance: InsuranceLogo,
    membership: MembershipLogo,
    package: PackageLogo,
    other: OtherTripLogo
};

export const MAP_TIPS_AND_ICONS: any = {
    calendar: Calendar,
    coin: Coin,
    credit_score: CreditScore,
    fee: Fee,
    interest_rates: InterestRates,
    timer: Timer,
    no_apr: NoAPR,
    play: Play,
    calculator: Calculator,
    calculator_percent: CalculatorPercent,
    world_green: WorldGreen,
    world_green_outline: WorldGreenOutline,
    sailing: Sailing,
    cart: Cart,
    percent: Percent,
    circle_1: CircleOne,
    circle_2: CircleTwo,
    application: Application,
    shopping_bag: ShoppingBag,
    amount_requested: AmountRequested,
}

export const MAP_STEPS_AND_ICONS: any = {
    send: Send,
    inProgress: InProgress,
    applicationDesicion: ApplicationDesicion,
    clientDesicion: ClientDesicion,
    purchase: Purchase,
}

export const MAP_APPLICATION_IN_PROGRESS_ICONS: any = {
    link: LinkIcon,
    agent: AgentIcon,
    applicationForm: ApplicationForm,
    checkmark: CheckmarkIcon,
    cardIcon: CardIcon,
    finishIcon: FinishIcon,
    copy: CopyIcon,
    states: {
        loan_approved: LoanApprovedIcon,
        loan_review: LoanDocumentationIcon,
        loan_reconfirm: LoanDocumentationIcon
    }
}

export { WorldIcon, InfoIcon, InfoIconFilled, WarningIcon, OpenInFullIcon, LinkStartApplicationIcon, CheckmarkIcon };
