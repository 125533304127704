import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from "amazon-cognito-identity-js";
import Pool, { Storage } from "@authentication/UserPool";
import { useTracking } from "@hooks/useTracking";
import { containsAction, parseToken } from "@helpers/Utils";
import { AdminTypes } from "@type";
import useGumsService from "@hooks/useGumsService";

type GumRole = {
  actions: Array<string>;
  resource: {
    teannt_id: number
  }
}

type AppRole = GumRole & {
  resource: {
    name: string
  }
}

type CustomACL = {
  app: Array<AppRole>
  gums: Array<GumRole>
}

export type AuthContext = {
    authenticate: (email: string, password: string) => Promise<any>,
    getSession: () => Promise<CognitoUserSession | null>,
    logout: () => Promise<any>,
    requestResetPassword: (email: string) => Promise<any>,
    resetPassword: (email: string, verificationCode: string, newPass: string) => Promise<any>,
    getErrorTranslationCode: (error: any) => string,
    completeNewPasswordChallenge: (user: any, Password: string) => Promise<any>,
    refreshAccessToken: () => Promise<any>,
    agentEmail: string | null,
    agentId: string | null,
    agentACL: CustomACL | null,
    selfAdminType: AdminTypes
};

const COGNITO_ERRORS_MAP: any = {
    NotAuthorizedException: [
      {
        msg: "Password attempts exceeded",
        key: "attempts"
      },
      {
        msg: "User password cannot be reset in the current state.",
        key: "cannot-reset"
      },
      {
        msg: "Temporary password has expired and must be reset by an administrator.",
        key: "pw-expired"
      },
    ],
    UserNotFoundException: "not-found",
    UserNotConfirmedException: "unconfirmed",
    LimitExceededException: "too-many",
    ExpiredCodeException: "expired",
    CodeMismatchException: "mismatch",
}

const mapCognitoAttributes = (attributes: Array<{Name: string, Value: string}>) => {
  return attributes.reduce((acc, curr) => {
    acc[curr.Name] = curr.Value

    return acc;
  }, {} as any);
}

const AuthenticationContext = createContext({} as AuthContext);

const Authentication = (props: any) => {
  const { trackAuth, trackAuthResult, trackAxios } = useTracking();
  const gumsApi = useGumsService();
  const [agentEmail, setAgentEmail] = useState<string | null>(null);
  const [agentId, setAgentId] = useState<string | null>(null);
  const [agentACL, setAgentACL] = useState<CustomACL | null>(null);
  const [selfAdminType, setSelfAdminType] = useState(AdminTypes.noAdmin);

  const getSessionsPromise = useRef<Promise<any> | null>(null)
  const refreshAccessTokenPromise = useRef<Promise<any> | null>(null)

  const getSession = useCallback((): Promise<CognitoUserSession | null> => {
    if (!getSessionsPromise.current)
      getSessionsPromise.current = new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (user) {
          user.getSession((err: Error, session: CognitoUserSession | null) => {
            if (err) {
              reject();
            } else {
              user.getUserData((err, data) => {
                if (err || session === null) {
                  return reject();
                }

                if (data) {
                  const { email } = mapCognitoAttributes(data.UserAttributes)
                  setAgentEmail(email);
                  setAgentId(data.Username);
                  setAgentACL((prev) => {
                    if (prev !== null) return prev;

                    const token = parseToken(session.getIdToken().getJwtToken());
                    const payload = JSON.parse(token);
                    const acl = JSON.parse(payload['custom:acl']);

                    return acl;
                  });
                  resolve(session);
                }
              })
            }
          });
        } else {
          reject();
        }
      }).finally(() => getSessionsPromise.current = null);

    return getSessionsPromise.current;
  }, []);

  const refreshAccessToken = useCallback(() => {
    if (!refreshAccessTokenPromise.current)
      refreshAccessTokenPromise.current = new Promise(async (resolve, reject) => {
        const session = await getSession();
        if (!session) return window.location.reload();

        const refreshToken = session.getRefreshToken();

        const user = Pool.getCurrentUser();
        user?.refreshSession(refreshToken, async (error, result) => {
          if (error) {
            if (error.name.includes("NotAuthorizedException") ||
                error.name.includes("PasswordResetRequiredException")) {
                Storage.clear();
                window.location.reload();
            }
          } else {
            resolve(result);
          }
        });
      }).finally(() => refreshAccessTokenPromise.current = null);

      return refreshAccessTokenPromise.current;
  }, [getSession]);

  const authenticate = useCallback((email: string, password: string) => {
    return new Promise(async (resolve, reject) => {
      if (!trackAuth || !trackAuthResult) return reject();

      const Username = email.toLocaleLowerCase();

      trackAuth('sign-in', '');

      const ipResponse =  await gumsApi.getIp();

      const user = new CognitoUser({ Username, Pool, Storage });
      const authDetails = new AuthenticationDetails({
        Password: password,
        Username,
        ClientMetadata: {
          requestId: ipResponse?.data?.request_id,
          sourceIp: ipResponse?.data?.ip,
          source: "standalone"
        }
      });

      user.setAuthenticationFlowType("CUSTOM_AUTH");

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          trackAuthResult("sign-in", "success");
          resolve({ ...data, user });
        },
        onFailure: (err) => {
          trackAuthResult("sign-in", "fail");
          reject(err);
        },
        newPasswordRequired: (data, requiredAttributes) => {
          trackAuthResult("sign-in", "reset-pw");
          resolve({ userAttributes: data, newPasswordRequired: true, user });
        },
        customChallenge: (params) => {
          resolve({ customChallenge: true, user, params})
        }
      });
    });
  }, [trackAuthResult, trackAuth, gumsApi]);

  const logout = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      if (!trackAxios) return reject();

      const user = Pool.getCurrentUser();

      if (!user) return reject();

      const session = await getSession();

      if (!session) return reject();

      user.setSignInUserSession(session);

      if (user) {
        trackAxios("sign-out", "POST");
        user.globalSignOut({
          onFailure: () => {
            window.location.reload();
            reject();
          },
          onSuccess: resolve
        });
        }
    })
  }, [trackAxios, getSession]);

  const completeNewPasswordChallenge = useCallback((user: any, Password: string) => {
    return new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(Password, null, {
        onSuccess: (data: any) => {
          resolve(data);
        },
        onFailure: (error: any) => {
          reject(error);
        }
      })
    });
  }, []);

  const requestResetPassword = useCallback((email: String) => {
    return new Promise((resolve, reject) => {
      if (!trackAuth || !trackAuthResult) return reject();

      trackAuth('send-verification-code', '');

      const user = new CognitoUser({ Username: email.toLowerCase(), Pool, Storage  });
      
      user.forgotPassword({
        onSuccess: (data) => {
          trackAuthResult("send-verification-code", 'success');
          resolve(data);
        },
        onFailure: (err) => {
          trackAuthResult("send-verification-code", 'fail');
          reject(err);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        }
      })
    })
  }, [trackAuthResult, trackAuth]);

  const resetPassword = useCallback((email: string, verificationCode: string, newPass: string) => {
    return new Promise((resolve, reject) => {
      if (!trackAuth || !trackAuthResult) return reject();

      const user = new CognitoUser({ Username: email.toLowerCase(), Pool, Storage  });
      
      user.confirmPassword(verificationCode, newPass, {
        onSuccess: (data) => {
          trackAuthResult("verify-reset-pw", "success");
          resolve(data);
        },
        onFailure: (err) => {
          trackAuthResult("verify-reset-pw", "fail");
          reject(err);
        }
      })
    })
  }, [trackAuthResult, trackAuth]);

  const getErrorTranslationCode = useCallback((error: any) => {
    const errorCode = COGNITO_ERRORS_MAP[error.code];

    if (!errorCode) return "catch-all";
    if (typeof errorCode === 'string') return errorCode;

    const subCode = errorCode.find((code: any) => {
      return error.message.includes(code.msg);
    }); 

    if (!subCode) return 'invalid';

    return subCode.key;
  }, []);

  useEffect(() => {
    if (!agentACL || !agentACL.gums || agentACL.gums.length === 0) return;

    const upliftGumRoles = agentACL.gums
                                  .filter((role: any) => role.resource.tenant_id.toString() === process.env.REACT_APP_UPLIFT_TENANT_ID)

    const somePartners = agentACL.gums.length > 1;
    const superAdmin = containsAction(upliftGumRoles, "**");
    const csAdmin = containsAction(agentACL.gums, "power");
    const upliftAdmin = upliftGumRoles.some((role: any) => {
        return ['write', 'read'].every(x => role.actions.includes(x));
    });

    if (superAdmin && upliftAdmin) setSelfAdminType(AdminTypes.omniAdmin);
    else if (superAdmin) setSelfAdminType(AdminTypes.superAdmin);
    else if (csAdmin) setSelfAdminType(AdminTypes.csAdmin);
    else if (somePartners) setSelfAdminType(AdminTypes.multiAdmin);
    else setSelfAdminType(AdminTypes.singleAdmin);

  }, [setSelfAdminType, agentACL])


  return (
    <AuthenticationContext.Provider value={{ agentEmail, refreshAccessToken, authenticate, getSession, logout, resetPassword, requestResetPassword, completeNewPasswordChallenge, getErrorTranslationCode, agentId, agentACL, selfAdminType }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export { Authentication, AuthenticationContext };
